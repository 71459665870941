import { t } from "i18next";
import { ChangeEvent, FC, useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import HeaderRowWrapper from "@app/components/RowWrapper";
import { RoutePaths } from "@app/routes/index";
import {
    addSelectedSalesChannels,
    getSelectedSalesChannel,
} from "@app/services/Endpoints/SelectSalesChannel.API";
import { ModalType, SelectSalesChannelErrors } from "@app/types/Common.types";
import { SalesChannel, SalesChannelData, SalesChannels } from "@app/types/SelectSalesChannel.types";
import { AppContext } from "@app/utils/contexts/App.context";
import { FormContext } from "@app/utils/contexts/Form.context";

const SelectSalesChannelContainer: FC = () => {
    const location = useLocation();
    const { triggerShowModal, setActions, isDirty, setIsDirty } = useContext(FormContext);
    const { userPermissions } = useContext(AppContext);
    const [salesChannel, setSalesChannel] = useState<SalesChannel[]>([]);
    const [salesChannelValues, setSalesChannelValues] = useState<Record<string, string>>({});
    const [, setSelectedSalesChannel] = useState<SalesChannels>({});
    const [checked, setChecked] = useState<string[]>([]);
    const [errors, setErrors] = useState<SelectSalesChannelErrors>();
    const navigate = useNavigate();

    const fetchSelectedSalesChannel = useCallback(() => {
        getSelectedSalesChannel()
            .then((res) => {
                if (res) {
                    const salesChannels: SalesChannels = res.data.data.salesChannels;
                    const salesChannelList: SalesChannel[] = Object.values(salesChannels).map(
                        (channel) => ({
                            id: channel.salesChannelId,
                            name: channel.name,
                            isSelectedOnBranch: channel.isSelectedOnBranch,
                            salesChannelId: channel.salesChannelId,
                            showOnCod: channel.showOnCod,
                            weightage: channel.weightage,
                        }),
                    );
                    setSalesChannel(salesChannelList);
                    setSelectedSalesChannel(res.data.data.salesChannels);
                    const selectedKeys = Object.entries(res.data.data.salesChannels)
                        .filter(([_, channel]) => channel.isSelectedOnBranch)
                        .map(([key]) => key);
                    setChecked(selectedKeys);
                    updateSalesChannel1(salesChannels);
                }
            })
            .catch();
    }, []);

    useEffect(() => {
        fetchSelectedSalesChannel();
    }, [fetchSelectedSalesChannel]);

    const initialWeightageValue = (channels: SalesChannel[], salesChannels: SalesChannels) => {
        const salesChannelData = channels.reduce((acc, channel) => {
            acc[channel.id] = salesChannels[channel.id]?.weightage?.toString() || "";
            return acc;
        }, {} as Record<string, string>);
        setSalesChannelValues(salesChannelData);
    };
    const updateSalesChannel1 = (salesChannels: SalesChannels) => {
        setSalesChannelValues((prev) => {
            const updatedValues = { ...prev };
            const filteredChannels = Object.values(salesChannels).filter(
                (channel) => channel.weightage > 0,
            );
            filteredChannels.forEach((channel) => {
                updatedValues[channel.salesChannelId] = channel.weightage.toString();
            });
            return updatedValues;
        });
    };
    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setIsDirty(true);
        const { name, value } = e.target;
        if (errors?.Weightage?.includes(name)) {
            setErrors({
                ...errors,
                Weightage: errors.Weightage.filter((item) => item !== name),
            });
        }
        setSalesChannelValues((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleCheckAllChange = () => {
        setIsDirty(true);
        setErrors(undefined);

        const shouldCheckAll = !(checked.length === salesChannel.length);
        const updatedChannels = salesChannel.map((c) => ({
            ...c,
            isSelectedOnBranch: shouldCheckAll,
        }));

        setSalesChannel(updatedChannels);
        setChecked(shouldCheckAll ? updatedChannels.map((c) => c.id) : []);
    };

    const handleSalesChannelChange = (c: SalesChannel) => {
        setIsDirty(true);
        if (errors?.Weightage?.includes(c.id)) {
            setErrors({
                ...errors,
                Weightage: errors.Weightage.filter((item) => item !== c.id),
            });
        }

        const isSelected = !c.isSelectedOnBranch;
        const updatedChannel = salesChannel.map((e) =>
            e.id === c.id ? { ...e, isSelectedOnBranch: isSelected } : e,
        );
        setSalesChannel(updatedChannel);

        setChecked((prevChecked) =>
            isSelected ? [...prevChecked, c.id] : prevChecked.filter((id) => id !== c.id),
        );
    };

    const handleShowCodChange = (id: string, isShowOnCod: boolean) => {
        setIsDirty(true);
        const updatedChannel = salesChannel.map((e) =>
            e.id === id ? { ...e, showOnCod: isShowOnCod } : e,
        );
        setSalesChannel(updatedChannel);
    };

    const fetchAndSetSalesChannelsData = () => {
        getSelectedSalesChannel().then((res) => {
            if (res?.data?.data?.salesChannels) {
                initialWeightageValue(salesChannel, res.data.data.salesChannels);
            }
        });
    };

    const handleCancel = () => {
        if (isDirty) {
            triggerShowModal(true, ModalType.CANCEL);
            setActions({
                done: () => {
                    triggerShowModal(false, ModalType.CANCEL);
                    setIsDirty(false);
                    setErrors(undefined);
                    fetchSelectedSalesChannel();
                    fetchAndSetSalesChannelsData();
                },
                cancel: () => {
                    triggerShowModal(false, ModalType.CANCEL);
                },
            });
        }
    };

    const handleBack = () => {
        if (isDirty) {
            triggerShowModal(true, ModalType.CANCEL);
            setActions({
                done: () => {
                    triggerShowModal(false, ModalType.CANCEL);
                    setIsDirty(false);
                    setErrors(undefined);

                    fetchAndSetSalesChannelsData();

                    navigate(`/${RoutePaths.SelectProducts}`, {
                        state: {
                            NavigateBackFromSalesChannel: "NavigateBackFromSalesChannel",
                        },
                    });
                },
                cancel: () => {
                    triggerShowModal(false, ModalType.CANCEL);
                },
            });
        } else {
            navigate(`/${RoutePaths.SelectProducts}`, {
                state: {
                    NavigateBackFromSalesChannel: "NavigateBackFromSalesChannel",
                },
            });
        }
    };

    const handleAddSalesChannel = (channels: SalesChannelData[]) => {
        addSelectedSalesChannels({ channels })
            .then(() => {
                triggerShowModal(false, ModalType.SUBMIT);
                setIsDirty(false);
                if (
                    location.state?.NavigateViaSelectProduct ||
                    location.state?.NavigateBackFromBranchSettings
                ) {
                    navigate(`/${RoutePaths.BranchSettings}`, {
                        state: { NavigateViaSelectSalesChannels: "NavigateViaSelectSalesChannels" },
                    });
                }
            })
            .catch(() => {
                triggerShowModal(false, ModalType.SUBMIT);
            });
    };

    const handleSubmit = () => {
        const channels: SalesChannelData[] = salesChannel.map((channel) => ({
            isSelectedOnBranch: channel.isSelectedOnBranch,
            name: channel.name,
            salesChannelId: channel.id,
            showOnCod: channel.showOnCod,
            weightage: Number(salesChannelValues[channel.id]) || 0,
        }));
        const isAtLeastOneSelected = checked.length > 0;
        const isValuesValid = checked.every((channelId) => !(salesChannelValues[channelId] === ""));
        const isDataValid = isAtLeastOneSelected && isValuesValid;

        if (isDataValid) {
            setErrors(undefined);
            triggerShowModal(true, ModalType.SUBMIT);
            setActions({
                done: () => handleAddSalesChannel(channels),
                cancel: () => triggerShowModal(false, ModalType.SUBMIT),
            });
        } else {
            const currentErrors: SelectSalesChannelErrors = {
                Weightage: [],
                atLeastOneSelected: "",
            };
            if (!isAtLeastOneSelected)
                currentErrors.atLeastOneSelected = "Please select at least one sales channel.";

            if (!isValuesValid) {
                checked.forEach((channelId) => {
                    if (salesChannelValues[channelId] === "") {
                        currentErrors.Weightage.push(channelId);
                    }
                });
            }
            setErrors({ ...errors, ...currentErrors });
        }
    };

    return (
        <HeaderRowWrapper
            heading={`${t("SELECTSALESCHANNEL.HEADING")}`}
            headerName={[
                `${t("SELECTSALESCHANNEL.CHANNEL")}`,
                `${t("SELECTSALESCHANNEL.SHOWONCOD")}`,
                `${t("SELECTSALESCHANNEL.SERVICEGOALTIME")}`,
            ]}
            checked={checked}
            salesChannel={salesChannel}
            salesChannelValues={salesChannelValues}
            onClickHeaderCheckBox={handleCheckAllChange}
            onClickRowCheckBox={handleSalesChannelChange}
            OnChangeInputFields={handleInputChange}
            OnClickCancel={handleCancel}
            OnClickSubmit={handleSubmit}
            OnClickBack={handleBack}
            submitDisabled={!isDirty}
            error={errors}
            hideSubmit={!(userPermissions && userPermissions["Create_Kitchen Setup"]) || false}
            onClickCodCheckBox={handleShowCodChange}
        />
    );
};
export default SelectSalesChannelContainer;

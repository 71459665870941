import { t } from "i18next";
import { FC, useCallback, useContext, useEffect, useState } from "react";

import FormWrapper from "@app/components/FormWrapper";
import SystemConfigurationForm from "@app/components/forms/SystemConfiguration.form";
import {
    addSystemConfiguration,
    getSystemConfiguration,
} from "@app/services/Endpoints/SystemConfiguration";
import { ModalType, SystemConfigurationErrors } from "@app/types/Common.types";
import { SystemConfigurationFormData } from "@app/types/SystemConfiguration";
import { FormContext } from "@app/utils/contexts/Form.context";

const initialFormData: SystemConfigurationFormData = {
    delayTime: "",
    outOfQueue: false,
    wastage: false,
    bulk: false,
    bulkBuffer: "",
    optimizeClubbing: false,
    HTThreshold: "",
};

interface SystemConfigurationContainerProps {
    systemConfiguration?: SystemConfigurationFormData;
}

const SystemConfigurationContainer: FC<SystemConfigurationContainerProps> = ({
    systemConfiguration,
}) => {
    const { triggerShowModal, setActions, isDirty, setIsDirty } = useContext(FormContext);
    const [newSystemConfiguration, setNewSystemConfiguration] =
        useState<SystemConfigurationFormData>(initialFormData);
    const [errors, setErrors] = useState<Partial<SystemConfigurationErrors>>();

    const fetchSystemConfiguration = useCallback(() => {
        getSystemConfiguration()
            .then((res) => {
                if (res) {
                    const delayTime =
                        res.data.data.find((d) => d.name === "SERVED_BUTTON_DELAY_TIME")?.value ??
                        "0";
                    const bulkBuffer =
                        res.data.data.find((d) => d.name === "BULK_BUFFER")?.value ?? "0";
                    const outOfQueue =
                        res.data.data
                            .find((d) => d.name === "OUT_OF_QUEUE")
                            ?.value?.toLowerCase() === "true";
                    const wastage =
                        res.data.data.find((d) => d.name === "WASTAGE")?.value?.toLowerCase() ===
                        "true";
                    const bulk =
                        res.data.data.find((d) => d.name === "BULK")?.value?.toLowerCase() ===
                        "true";
                    const optimizeClubbing =
                        res.data.data.find((d) => d.name === "OPTIMIZE_CLUBBING")?.value?.toLowerCase() ===
                        "true";
                    const HTThreshold =
                        res.data.data.find((d) => d.name === "HT_THRESHOLD")?.value ?? "100";
                    setNewSystemConfiguration({ delayTime, outOfQueue, wastage, bulk, bulkBuffer, optimizeClubbing, HTThreshold });
                } else {
                    setNewSystemConfiguration({ ...initialFormData });
                }
            })
            .catch(() => setNewSystemConfiguration({ ...initialFormData }));
    }, []);

    useEffect(() => {
        fetchSystemConfiguration();
    }, [fetchSystemConfiguration]);

    useEffect(() => {
        if (systemConfiguration) {
            const { delayTime, outOfQueue, wastage, bulk, bulkBuffer, optimizeClubbing, HTThreshold } = {
                ...systemConfiguration,
            };

            setNewSystemConfiguration({
                delayTime,
                outOfQueue,
                wastage,
                bulk,
                bulkBuffer,
                optimizeClubbing,
                HTThreshold
            });
        }
    }, [systemConfiguration]);

    const handleChange = (name: string, value: string | boolean) => {
        setIsDirty(true);
        const data: SystemConfigurationFormData = { ...newSystemConfiguration };
        if (errors?.[name]) {
            setErrors({ ...errors, [name]: "" });
        }
        setNewSystemConfiguration({ ...data, [name]: value });
    };

    const handleCreateSystemConfiguration = () => {
        const { bulkBuffer, ...formData } = newSystemConfiguration;

        addSystemConfiguration(
            newSystemConfiguration.bulk ? { ...newSystemConfiguration } : { ...formData },
        )
            .then(() => {
                triggerShowModal(false, ModalType.SUBMIT);
                setIsDirty(false);
            })
            .catch(() => {
                triggerShowModal(false, ModalType.SUBMIT);
            });
    };

    const handleSubmit = () => {
        const { delayTime, bulkBuffer, bulk, optimizeClubbing, HTThreshold } = newSystemConfiguration;
        const isDelayTimeValid = !!delayTime;
        const isBulkBuffer = !!bulkBuffer;
        const isBulkBufferGreater = isBulkBuffer && Number(bulkBuffer) > 0;
        const isBulkBufferSmaller = isBulkBuffer && Number(bulkBuffer) <= 100;
        const isBulkBufferValid = bulk ? isBulkBufferGreater && isBulkBufferSmaller : true;
        const isHTThreshold = !!HTThreshold;
        const isHTThresholdGreater = isHTThreshold && Number(HTThreshold) >= 0;
        const isHTThresholdSmaller = isHTThreshold && Number(HTThreshold) <= 200;
        const isHTThresholdValid = optimizeClubbing ? isHTThresholdGreater && isHTThresholdSmaller : true;
        const isDataValid = isDelayTimeValid && isBulkBufferValid && isHTThresholdValid;

        if (isDataValid) {
            setErrors(undefined);
            triggerShowModal(true, ModalType.SUBMIT);
            setActions({
                done: () => handleCreateSystemConfiguration(),
                cancel: () => triggerShowModal(false, ModalType.SUBMIT),
            });
        } else {
            const currentErrors: Partial<SystemConfigurationErrors> = {};
            if (!isDelayTimeValid)
                currentErrors.delayTime = `${t("ERRORS.REQUIRED", {
                    name: "Delay Time",
                })}`;
            if (!isBulkBufferValid)
                if (isBulkBufferGreater)
                    currentErrors.bulkBuffer = "Buffer value should not be greater than 100";
                else if (isBulkBufferSmaller)
                    currentErrors.bulkBuffer = "Buffer value should be greater than 0";
                else
                    currentErrors.bulkBuffer = `${t("ERRORS.REQUIRED", {
                        name: "Bulk Buffer",
                    })}`;
            if (!isHTThresholdValid)
                if (isHTThresholdGreater)
                    currentErrors.HTThreshold = "Threshold value should not be greater than 200";
                else if (isHTThresholdSmaller)
                    currentErrors.HTThreshold = "Threshold value should be greater than 0";
                else
                    currentErrors.HTThreshold = `${t("ERRORS.REQUIRED", {
                        name: "Threshold for HT",
                    })}`;
            setErrors({ ...errors, ...currentErrors });
        }
    };

    return (
        <FormWrapper
            heading={"Fill in details for system configurations"}
            onSubmitClick={handleSubmit}
            submitDisabled={!isDirty}
        >
            <SystemConfigurationForm
                data={newSystemConfiguration}
                onChange={handleChange}
                errors={errors}
            />
        </FormWrapper>
    );
};

export default SystemConfigurationContainer;

import { FC, useContext } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";

import Checkbox from "@app/components/inputs/Checkbox";
import { BranchSettingsFormData } from "@app/types/BranchSettings";
import { AppContext } from "@app/utils/contexts/App.context";

interface BranchSettingsFormProps {
    data: BranchSettingsFormData;
    onChange: (_name: string, _value: string | boolean) => void;
}

const BranchSettingsForm: FC<BranchSettingsFormProps> = ({ data, onChange }) => {
    const { userPermissions } = useContext(AppContext);

    return (
        <Container fluid className='py-4 px-5'>
            <Row>
                <Col>
                    <Form.Group>
                        <Form.Label className='text-input__label mb-4'>Select Features</Form.Label>
                        <div className='d-flex'>
                            <Checkbox
                                checked={data.BULK_LICENSEE}
                                label='Bulk'
                                onClick={() => onChange("BULK_LICENSEE", !data.BULK_LICENSEE)}
                                testId='bulk-checkbox'
                                className='bulk-checkbox'
                            />
                            {userPermissions &&
                                (userPermissions["Read_Auto Pumping"] ||
                                    userPermissions["Edit_Auto Pumping"]) && (
                                    <Checkbox
                                        checked={data.AUTOPUMP_LICENSEE}
                                        label='Auto-Pumping'
                                        onClick={() =>
                                            onChange("AUTOPUMP_LICENSEE", !data.AUTOPUMP_LICENSEE)
                                        }
                                        testId='auto-pump-checkbox'
                                        disabled={
                                            userPermissions && !userPermissions["Edit_Auto Pumping"]
                                        }
                                    />
                                )}
                        </div>
                    </Form.Group>
                </Col>
            </Row>
        </Container>
    );
};

export default BranchSettingsForm;

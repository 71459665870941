import { ReactElement, ReactNode } from "react";

import { Branch } from "./Branch.types";
import { Licensee } from "./Licensee";
import { Products } from "./Product.types";
import { Role, User } from "./User.type";
import { Item } from "./items.types";

export interface WindowSize {
    width?: number;
    height?: number;
}

export interface SidebarOptions {
    path: string;
    label: string;
    primaryText: string;
    secondaryText: string;
    element: ReactElement;
    disableSideLink?: boolean;
    staticLink?: boolean;
    authorized?: boolean;
}

interface HeaderMapsObj {
    label: string;
    layout: { lg?: number; sm: number };
    disableSort?: boolean;
}
export interface HeadersMapInterface {
    [key: string]: HeaderMapsObj;
}

export interface Errors {
    [key: string]: string;

    // Machine Errors
    machineMake: string;
    newMachineManufacturer: string;
    machineName: string;
    noOfBaskets: string;
    machineType: string;
    applicableStations: string;

    // User Role Errors
    name: string;
    userLevel: string;
    features: string;

    // User Erros
    id: string;
    roleID: string;
    licenseeID: string;
}

export interface SystemConfigurationErrors {
    [key: string]: string;

    // system configuration
    delayTime: string;
    branches: string;
}

export interface SelectSalesChannelErrors {
    [key: string]: string | string[];

    // select sales channel
    Weightage: string[];
    atLeastOneSelected: string;
}

export interface SelectProductErrors {
    [key: string]: string | string[];

    // select sales channel
    assemblyCapacity: string[];
    atLeastOneSelected: string;
}

export interface ProductErrors {
    [key: string]: string | ItemErrors[];

    // Product Errors
    name: string;
    displayName: string;
    code: string;
    holdingTime: string;
    assemblyCapacity: string;
    productCategoryId: string;
    imageId: string;
    assemblyStationId: string;
    items: ItemErrors[];
}

export interface ItemErrors {
    itemId: string;
    quantity: string;
}

export interface CustomizationErrors {
    [key: string]: string;
    name: string;
    displayName: string;
    code: string;
    imageId: string;
}

export interface BranchErrors {
    [key: string]: string | UserErrors[];

    name: string;
    code: string;
    branchType: string;
    countryID: string;
    regionID: string;
    cityID: string;
    address: string;
    pinCode: string;
    licenseeID: string;
    is24HourOpen: string;
    startTime: string;
    endTime: string;
    users: UserErrors[];
    invalidUsers: string;
}

export interface UserErrors {
    id: string;
    roleId: string;
}

export enum ModalType {
    // eslint-disable-next-line
    SUBMIT = "SUBMIT",
    // eslint-disable-next-line
    CANCEL = "CANCEL",
    // eslint-disable-next-line
    INACTIVE_SUBMISSION = "INACTIVE_SUBMISSION",
    // eslint-disable-next-line
    COOKING_STATION_SELECTION = "COOKING_STATION_SELECTION",
    // eslint-disable-next-line
    INACTIVE_ITEM_DENIED = "INACTIVE_ITEM_DENIED",
    // eslint-disable-next-line
    INACTIVE_MACHINE_DENIED = "INACTIVE_MACHINE_DENIED",
    // eslint-disable-next-line
    INACTIVE_LICENSEE_USER_DENIED = "INACTIVE_LICENSEE_USER_DENIED",
    // eslint-disable-next-line
    INACTIVE_BRANCH_USER_DENIED = "INACTIVE_BRANCH_USER_DENIED",
    // eslint-disable-next-line
    INACTIVE_ROLE_DENIED = "INACTIVE_ROLE_DENIED",
}

export enum ScreenType {
    // eslint-disable-next-line
    USER_ROLE = "USER_ROLE",
    // eslint-disable-next-line
    USER = "USER",
    // eslint-disable-next-line
    ITEM = "ITEM",
    // eslint-disable-next-line
    INACTIVE_ITEM_DENIED = "INACTIVE_ITEM_DENIED",
    // eslint-disable-next-line
    LICENSEE = "LICENSEE",
    // eslint-disable-next-line
    MACHINE = "MACHINE",
    // eslint-disable-next-line
    INACTIVE_MACHINE_DENIED = "INACTIVE_MACHINE_DENIED",
    // eslint-disable-next-line
    INACTIVE_LICENSEE_USER_DENIED = "INACTIVE_LICENSEE_USER_DENIED",
    // eslint-disable-next-line
    INACTIVE_BRANCH_USER_DENIED = "INACTIVE_BRANCH_USER_DENIED",
    // eslint-disable-next-line
    INACTIVE_ROLE_DENIED = "INACTIVE_ROLE_DENIED",
}

export enum ResponseTypes {
    // eslint-disable-next-line
    INVALID_INPUT = "INVALID_INPUT",
    // eslint-disable-next-line
    INVALID_REQUEST = "INVALID_REQUEST",
    // eslint-disable-next-line
    DUPLICATE_ENTITY = "DUPLICATE_ENTITY",
    // eslint-disable-next-line
    UPDATED = "UPDATED",
    // eslint-disable-next-line
    CREATED = "CREATED",
    // eslint-disable-next-line
    UNAUTHORIZED = "UNAUTHORIZED",
    // eslint-disable-next-line
    TANJARA_TRAY_ADDED = "TANJARA_TRAY_ADDED",
    // eslint-disable-next-line
    MACHINE_DELETED = "MACHINE_DELETED",
    // eslint-disable-next-line
    INVALID_ROLE = "INVALID_ROLE",
    // eslint-disable-next-line
    PRODUCT_NOT_FOUND = "PRODUCT_NOT_FOUND",
    // eslint-disable-next-line
    MACHINE_NOT_DELETED = "MACHINE_NOT_DELETED",
}

export interface DisplayData extends User, Role, Item, Products, Branch, Licensee {
    licenseeID: string;
    code: string;
    users: [];
    branchName: string;
}

export interface SelectOption {
    value: string;
    label: string;
    isdisabled?: boolean;
}

export interface UserPermissions {
    
    Create_Branch: boolean;
    Create_Customization: boolean;
    Create_Item: boolean;
    Create_Licensee: boolean;
    Create_Machine: boolean;
    Create_Product: boolean;
    Create_User: boolean;
    Create_User_Role: boolean;
    Create_System_Config: boolean;
    Deactivate_Branch: boolean;
    Deactivate_Customization: boolean;
    Deactivate_Item: boolean;
    Deactivate_Licensee: boolean;
    Deactivate_Machine: boolean;
    Deactivate_Product: boolean;
    Deactivate_User: boolean;
    Deactivate_User_Role: boolean;
    Edit_Branch: boolean;
    Edit_Customization: boolean;
    Edit_Item: boolean;
    Edit_Licensee: boolean;
    Edit_Machine: boolean;
    Edit_Product: boolean;
    Edit_User: boolean;
    Edit_User_Role: boolean;
    Edit_System_Config: boolean;
    Read_Branch: boolean;
    Read_Customization: boolean;
    Read_Item: boolean;
    Read_Licensee: boolean;
    Read_Machine: boolean;
    Read_Product: boolean;
    Read_User: boolean;
    Read_User_Role: boolean;
    ["Create_Kitchen Setup"]: boolean;
    ["Read_Sharable Items"]: boolean;
    ["Edit_Sharable Items"]: boolean;
    ["Read_Auto Pumping"]:boolean;
    ["Edit_Auto Pumping"]:boolean;

}

export interface FilterOptions {
    route: string;
    filterComponent: ReactNode;
}

import { OperationalAPIService } from "@app/services/Methods/api";
import { PostSalesChannelResponse, SelectedSalesChannelResponse } from "@app/types/API.types";
import { Channel } from "@app/types/SelectSalesChannel.types";

export const getSelectedSalesChannel = () =>
    OperationalAPIService.get<SelectedSalesChannelResponse>("/saleschannel/GetSalesChannels");

export const addSelectedSalesChannels = (channels: Channel) =>
    OperationalAPIService.post<PostSalesChannelResponse>(
        "/saleschannel/SelectSalesChannels",
        channels,
    );
